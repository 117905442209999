<template>
  <!-- <v-col cols="12" class="tabla">
    <v-card-title>
      <span class="headline">Declaración Jurada</span>
      <v-spacer></v-spacer>
      <v-btn @click="NuevaDeclaracion()" dark>Nueva DDJJ</v-btn>
    </v-card-title> -->
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    hide-overlay
    scrollable
    transition="dialog-bottom-transition"
    class="zindex-dialog"
  >
    <v-card>
      <v-app-bar color="oscuro" fixed dark>
        <v-spacer></v-spacer>
        <v-toolbar-title class="mayuscula"
          >DDJJ de {{ user.datoscomercio.descripcion }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn @click="NuevaDeclaracion()" class="mr-2" dark>Nueva DDJJ</v-btn>

        <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-app-bar>
      <v-card-text class="margenappbar">
        <v-data-table
          v-model="cuotasseleccionadas"
          :headers="headerscuotas"
          :items="cuotas"
          item-key="cunica"
          show-select
          class="custom-header-style"
          :items-per-page="-1"
          group-by="anio"
          ref="tablacuotas"
          hide-default-footer
          disable-sort
        >
          <template
            v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :value="isSelected"
              @input="select($event)"
              :ripple="false"
              @click="clickaso(item)"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
            <th :colspan="2">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0].anio }}
            </th>
            <th class="text-right"></th>
            <th class="text-right"></th>
            <th class="text-right"></th>
            <th class="text-right"></th>
            <th class="text-right"></th>
            <th class="text-right">${{ SumaPorAnio(items, "original") }}</th>
            <th class="text-right">${{ SumaPorAnio(items, "actualizado") }}</th>
            <th class="text-right">${{ SumaPorAnio(items, "descuento") }}</th>
            <th class="text-right">${{ SumaPorAnio(items, "total") }}</th>
          </template>
          <template v-slot:[`item.estadodeuda`]="{ item }">{{
            Estado(item)
          }}</template>
          <template v-slot:[`item.fecha_vencimiento`]="{ item }">{{
            item.fecha_ven1 | moment("DD/MM/YYYY")
          }}</template>
          <template v-slot:[`item.original_lindo`]="{ item }"
            >${{ new Intl.NumberFormat("ES").format(item.importe) }}</template
          >
          <template v-slot:[`item.alicuota_lindo`]="{ item }"
            >${{ item.alicuota.toFixed(4) }}</template
          >
          <template v-slot:[`item.descuento_lindo`]="{ item }">{{
            new Intl.NumberFormat("ES").format(item.descuento)
          }}</template>
          <template v-slot:[`item.recargos_lindo`]="{ item }"
            >${{ new Intl.NumberFormat("ES").format(item.recargo) }}</template
          >
          <template v-slot:[`item.total_lindo`]="{ item }">
            ${{ new Intl.NumberFormat("ES").format(item.totalcuota) }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="custom-actions">
        <div style="position: absolute; left: 50%; transform: translateX(-50%)">
          <v-btn dark color="error" class="mr-2" @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn
            dark
            class="font-weight-bold"
            color="claro"
            @click="AbrirCuotas()"
          >
            Siguiente
          </v-btn>
        </div>

        <div class="footertotal" style="margin-left: auto">
          <h2 class="mr-2">Total</h2>
          <v-card dark flat outlined height="30" width="130" class="mr-2">
            <h3 style="margin-top: 2px; text-align: right" class="mr-2">
              ${{ SumaCuotasSeleccionadas("totalcuota") }}
            </h3>
          </v-card>
        </div>
      </v-card-actions>
      <v-dialog v-model="DialogPagarDeudas" width="1200" persistent>
        <v-card>
          <v-card-title
            class="headline"
            style="background-color: #4f798f; color: white"
          >
            CUOTAS SELECCIONADAS
          </v-card-title>
          <v-card-text style="padding: 0px">
            <v-data-table
              :headers="headerscuotasapagar"
              :items="cuotasapagar"
              :items-per-page="4"
              item-key="cunica"
              locale="es"
            >
              <template v-slot:[`item.fecha_vencimiento`]="{ item }">{{
                item.fecha_ven1 | moment("DD/MM/YYYY")
              }}</template>
              <template v-slot:[`item.original_lindo`]="{ item }"
                >${{ formatNumber(item.importe) }}</template
              >
              <template v-slot:[`item.alicuota_lindo`]="{ item }"
                >${{ item.alicuota.toFixed(4) }}</template
              >
              <template v-slot:[`item.descuento_lindo`]="{ item }"
                >${{ formatNumber(item.descuento) }}</template
              >
              <template v-slot:[`item.recargos_lindo`]="{ item }"
                >${{ formatNumber(item.recargo) }}</template
              >
              <template v-slot:[`item.total_lindo`]="{ item }">
                <b>${{ formatNumber(item.totalcuota) }}</b>
              </template>
            </v-data-table>
            <div class="footertotal">
              <h2 class="mr-2">Total a Pagar:</h2>
              <v-card dark flat outlined height="30" width="130" class="mr-2">
                <h3 style="margin-top: 2px; text-align: right" class="mr-2">
                  ${{ Sumacuotasapagar("totalcuota") }}
                </h3>
              </v-card>
            </div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="error"
              class="font-weight-bold"
              @click="DialogPagarDeudas = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              dark
              class="font-weight-bold botones_pagar"
              color="claro"
              @click="MetodosDePago = true"
            >
              Pagar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        transition="dialog-bottom-transition"
        width="350"
        v-model="MetodosDePago"
      >
        <v-card>
          <v-toolbar title="Opening from the top" dark>
            <v-toolbar-title>Seleccione el método de pago:</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-radio-group v-model="tipopago" inline>
              <v-radio
                label="Pago con tarjeta crédito/débito/qr"
                :value="1"
                color="#40A5DD"
                class="font-weight-bold"
              ></v-radio>
              <!-- <v-radio
              label="Interbanking"
              :value="2"
              color="#40A5DD"
              class="font-weight-bold"
            ></v-radio> -->
              <v-radio
                label="Imprimir recibo para pago"
                :value="3"
                color="#40A5DD"
                class="font-weight-bold"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn @click="MetodosDePago = false" outlined>cancelar</v-btn>
            <v-btn color="claro" dark @click="PagarCuotas(tipopago)"
              >Confirmar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="DialogNuevaddjj" width="900" persistent>
        <v-card>
          <v-form ref="formddjj" v-model="validformddjj" lazy-validation>
            <v-toolbar color="claro" dark flat>
              <v-toolbar-title>NUEVA DDJJ</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-card-text>
              <v-row>
                <v-col cols="4" class="mt-2">
                  <b>Tipo</b>
                  <!-- {{tipodeclaracion}} -->
                  <v-radio-group
                    v-model="tipodeclaracion"
                    row
                    style="margin-top: 5px"
                    @change="NuevaDeclaracion(true)"
                  >
                    <v-radio
                      label="Inicial"
                      :value="ValorDeclaracion('jurada')"
                    ></v-radio>
                    <v-radio
                      label="Rectificativa"
                      :value="ValorDeclaracion('rectificativa')"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="5" class="mt-7">
                  <v-autocomplete
                    :items="bimestres"
                    v-model="bimestre"
                    item-text="t_deta"
                    item-value="t_cod"
                    placeholder="Seleccione un bimestre"
                    :rules="[(v) => !!v || 'Seleccione un bimestre']"
                    required
                    @change="TraerSaldo(bimestre)"
                    outlined
                    dense
                    label="Bimestre"
                  >
                    <template v-slot:item="data">
                      {{ data.item.t_desvie }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <div
                    class="mt-3"
                    style="
                      text-align: right;
                      display: flex;
                      flex-direction: column;
                    "
                  >
                    <span class="font-weight-bold title"
                      >Total a pagar: ${{
                        new Intl.NumberFormat("ES").format(totalSubtotales)
                      }}</span
                    >
                    <span class="font-weight-medium subtitle"
                      >Total: ${{
                        new Intl.NumberFormat("ES").format(totalSubtotales)
                      }}</span
                    >
                    <span class="font-weight-medium subtitle"
                      >Saldo a favor: ${{
                        saldofavor
                          ? new Intl.NumberFormat("ES").format(saldofavor)
                          : 0
                      }}</span
                    >
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-for="(actividad, index) in actividades"
                  :key="index"
                  cols="4"
                >
                  <v-card outlined>
                    <v-card-title> Actividad {{ index + 1 }} </v-card-title>
                    <v-card-text>
                      <v-autocomplete
                        style="margin-top: -15px"
                        :items="itemsactividades"
                        v-model="actividad.actividad"
                        item-text="t_deta"
                        item-value="t_cod"
                        @change="Alicuota(index)"
                        placeholder="Seleccione una actividad"
                        outlined
                        dense
                        return-object
                      >
                        <template v-slot:item="data">
                          {{ data.item.t_deta }} -
                          {{ data.item.t_valor * 100 }}%
                        </template>
                      </v-autocomplete>
                      <v-text-field
                        v-model="actividad.ingreso"
                        label="Ingreso"
                        outlined
                        @change="Subtotal(index)"
                        dense
                      ></v-text-field>
                      <v-row>
                        <v-col md="6">
                          <p style="margin-bottom: 0px">Alicuota:</p>
                          <b>{{ actividad.alicuota }}</b>
                        </v-col>
                        <v-col md="6">
                          <p style="margin-bottom: 0px">Subtotal:</p>
                          <b>{{
                            new Intl.NumberFormat("ES").format(
                              actividad.subtotal
                            )
                          }}</b>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn color="error" @click="DialogNuevaddjj = false">
                Cancelar
              </v-btn>
              <v-btn dark color="claro" @click="GuardarDeclaracion()">
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
  <!-- </v-col> -->
</template>

<script>
export default {
  props: {
    user: Object,
  },
  data: () => ({
    dialog: false,
    headerscuotas: [
      { text: "CUOTA", align: "start", value: "cuota", class: "white--text" },
      { text: "TASA", value: "tasa", class: "white--text" },
      { text: "VENCIMIENTO", value: "fecha_vencimiento", class: "white--text" },
      { text: "ESTADO", value: "nomEstado", class: "white--text" },
      { text: "INGRESO", value: "ingreso", class: "white--text" },
      { text: "ALICUOTA", value: "alicuota_lindo", class: "white--text" },
      {
        text: "ORIGINAL",
        value: "original_lindo",
        class: "white--text",
        align: "right",
      },
      {
        text: "RECARGOS",
        value: "recargos_lindo",
        class: "white--text",
        align: "right",
      },
      { text: "DESCUENTO", value: "descuento_lindo", class: "white--text", align: "right" },

      {
        text: "TOTAL CUOTA",
        value: "total_lindo",
        class: "white--text",
        align: "right",
      },
    ],
    cuotas: [],
    cuotasseleccionadas: [],
    datospagardeuda: [],
    // totalapagar:"",
    CuentaMunicipal: "",
    headerscuotasapagar: [
      { text: "CUOTA", align: "start", value: "cuota" },
      { text: "TASA", value: "tasa" },
      { text: "VENCIMIENTO", value: "fecha_vencimiento" },
      { text: "ESTADO", value: "nomEstado" },
      { text: "INGRESO", value: "ingreso" },
      { text: "ALICUOTA", value: "alicuota_lindo" },
      { text: "ORIGINAL", value: "original_lindo" },
      { text: "RECARGOS", value: "recargos_lindo" },
      { text: "DESCUENTO", value: "descuento_lindo" },
      { text: "TOTAL CUOTA", value: "total_lindo" },
    ],
    cuotasapagar: [],
    DialogDeudas: false,
    DialogPagarDeudas: false,

    DialogNuevaddjj: false,
    tipodeclaracion: "",
    bimestre: "",
    bimestres: [],
    itemsactividades: [],
    actividades: [
      { actividad: 0, valor: 0, ingreso: 0, alicuota: 0, subtotal: 0 },
      { actividad: 0, valor: 0, ingreso: 0, alicuota: 0, subtotal: 0 },
      { actividad: 0, valor: 0, ingreso: 0, alicuota: 0, subtotal: 0 },
    ],
    totalSubtotales: 0,
    saldofavor: "",
    validformddjj: true,
    tipopago: null,
    MetodosDePago: false,
  }),

  computed: {
    cunicasseleccionadas() {
      return this.cuotasseleccionadas.map((v) => {
        return v.cunica;
      });
    },
  },

  watch: {
    // dialog(){
    //     if(this.DialogDeudas){
    //       this.ListadoCuotas();
    //     }
    // },
  },

  mounted() {
    // this.initialize();
    this.tipodeclaracion = this.user.tipo;
  },

  methods: {
    // nomenclatura_catastral(array){
    //   return `${this.datitosPadron(array, "nc_circ")}-${this.datitosPadron(array, "nc_sec")}-${this.datitosPadron(array, "nc_mzna")}-${this.datitosPadron(array, "nc_letmzna")}-${this.datitosPadron(array, "nc_parc")}-${this.datitosPadron(array, "nc_letparc")}-${this.datitosPadron(array, "nc_uf")}-${this.datitosPadron(array, "nc_chacra")}-${this.datitosPadron(array, "nc_lchacra")}-${this.datitosPadron(array, "nc_quinta")}-${this.datitosPadron(array, "nc_fracci")}`;
    // },

    initialize() {
      // this.ListadoCuotas();
    },

    ValorDeclaracion(tipo) {
      let valor;
      if (this.user.tipo == "gc") {
        switch (tipo) {
          case "jurada":
            valor = "gc";
            break;
          case "rectificativa":
            valor = "gc_rectificativa";
            break;
          default:
            valor = "";
            break;
        }
      } else {
        switch (tipo) {
          case "jurada":
            valor = "gp";
            break;
          case "rectificativa":
            valor = "gp_rectificativa";
            break;
          default:
            valor = "";
            break;
        }
      }
      return valor;
    },

    datitosPadron(array, dato) {
      return array ? array[0][dato] : "-";
    },

    ListadoCuotas(item) {
      console.log("datos comercio declaracion", this.user);
      this.cuotas = [];
      this.cuotasseleccionadas = [];
      this.datospagardeuda = [];
      var obj = this;
      this.$ajax
        .post(`${this.$apitramites}comercios/declaracionjurada`, {
          padron: item.padron,
        })
        .then(function (response) {
          console.log("Listado declaracion jurada", response);
          // obj.datospagardeuda=response.data;
          obj.cuotas = response.data.cuotasF;
          obj.dialog = true;
        })
        .catch(function (error) {
          obj.snackbar = true;
          obj.msj = error;
        });
    },

    SumaPorAnio(items, valor) {
      if (valor === "descuento") {
        const descuento = items.reduce((sum, item) => sum + item.descuento, 0);
        return this.formatNumber(descuento);
      }
      if (valor === "total") {
        const total = items.reduce((sum, item) => sum + item.totalcuota, 0);
        return this.formatNumber(total);
      }
      if (valor === "original") {
        const original = items.reduce((sum, item) => sum + item.importe, 0);
        return this.formatNumber(original);
      }
      if (valor === "actualizado") {
        const actualizado = items.reduce((sum, item) => sum + item.recargo, 0);
        return this.formatNumber(actualizado);
      }
    },

    formatNumber(number) {
      if (number >= 1000) {
        const formatted = new Intl.NumberFormat("es-ES").format(number);
        const parts = formatted.split(",");
        if (parts.length === 2) {
          return (
            parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "," + parts[1]
          );
        }
        return formatted;
      } else {
        return new Intl.NumberFormat("es-ES", {
          minimumFractionDigits: 2,
        }).format(number);
      }
    },

    Sumacuotasapagar(key) {
      // sum data in give key (property)
      const total = this.cuotasseleccionadas.reduce(
        (a, b) => a + (b[key] || 0),
        0
      );
      return this.formatNumber(total);
    },

    SumaCuotasSeleccionadas(key) {
      // sum data in give key (property)
      const total = this.cuotasseleccionadas.reduce(
        (a, b) => a + (b[key] || 0),
        0
      );
      return this.formatNumber(total);
    },

    TraerSaldo(bimestre) {
      console.log("bimestre", bimestre);
      var obj = this;
      this.$ajax
        .post(`${this.$apitramites}comercios/declaracionJurada/saldoAFavor`, {
          padron: this.user.datoscomercio.padron,
          chktipo: this.tipodeclaracion,
          bimestre: Number(bimestre),
        })
        .then(function (response) {
          console.log("Saldo a favor", response);
          obj.saldofavor = response.data.saldo;
        })
        .catch(function (error) {
          obj.snackbar = true;
          obj.msj = error;
        });
    },

    NuevaDeclaracion(check) {
      var obj = this;
      this.$ajax
        .post(`${this.$apitramites}comercios/declaracionJurada/datos`, {
          padron: this.user.datoscomercio.padron,
          tipo: check ? this.tipodeclaracion : this.user.tipo,
        })
        .then(function (response) {
          console.log("Datos nueva DDJJ", response);
          if (check) {
            obj.bimestres = response.data.bimestre;
            obj.itemsactividades = response.data.tabla;
          } else {
            obj.bimestres = response.data.bimestre;
            obj.DialogNuevaddjj = true;
            obj.itemsactividades = response.data.tabla;
          }
        })
        .catch(function (error) {
          obj.snackbar = true;
          obj.msj = error;
        });
    },

    GuardarDeclaracion() {
      const actividadesParaPost = this.actividades.map((actividad) => ({
        actividad: actividad.actividad.t_cod,
        valor: actividad.actividad.t_valor,
        ingreso: actividad.ingreso,
      }));

      var obj = this;
      if (this.$refs.formddjj.validate()) {
        this.$ajax
          .post(
            `${this.$apitramites}comercios/declaracionJurada/guardar`,
            {
              padron: this.user.datoscomercio.padron,
              chktipo: this.tipodeclaracion,
              bimestre: Number(this.bimestre),
              actividades: actividadesParaPost,
            },
            { responseType: "blob" }
          )
          .then(function (response) {
            console.log("Guardar Declaracion", response);
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });
            const pdfUrl = window.URL.createObjectURL(pdfBlob);
            window.open(pdfUrl);
            obj.vaciarExpanded();
          })
          .catch(function (error) {
            this.$swal.fire({
              title: "Error",
              text: error,
              icon: "error",
            });
          });
      }
    },

    AbrirCuotas() {
      if (this.cuotasseleccionadas.length != 0) {
        this.cuotasapagar = this.cuotasseleccionadas;
        console.log("Cuotas a pagar", this.cuotasapagar);
        this.DialogPagarDeudas = true;
      } else {
        this.$swal.fire({
          title: "Error",
          text: "Seleccione una cuota a pagar",
          icon: "error",
        });
        // alert("Seleccione una cuota a pagar");
      }
    },

    PagarCuotas(tipo) {
      console.log("tipo", tipo);
      console.log("cuota que van", this.cunicasseleccionadas);
      var obj = this;

      if (tipo == 3) {
        console.log("entra");
        this.$ajax
          .post(
            `${this.$apitramites}comercios/imprimirRecibo`,
            {
              padron: this.user.datoscomercio.padron,
              cunica: this.cunicasseleccionadas,
              tipo: "ddjj",
            },
            { responseType: "blob" }
          )
          .then(function (response) {
            console.log("imprimir recibo", response);
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });
            const pdfUrl = window.URL.createObjectURL(pdfBlob);
            window.open(pdfUrl);
            obj.vaciarExpanded();
          })
          .catch(function (error) {
            obj.snackbar = true;
            obj.msj = error;
          });
      } else if (tipo == 1) {
        console.log("entra tarjeta");
        this.$ajax
          .post(`${this.$apitramites}comercios/pagarCuotas`, {
            padron: this.user.datoscomercio.padron,
            cunica: this.cunicasseleccionadas,
            tipo: "ddjj",
            pago: "MACRO",
          })
          .then(function (response) {
            console.log("pagar tarjeta", response);
            obj.AbrirPaginaPago(response.data);
            // obj.$router.push('/pagina_redireccion');
          })
          .catch(function (error) {
            obj.snackbar = true;
            obj.msj = error;
          });
      } else {
        console.log("entra interbanking");
        this.$swal.fire({
          title: "Seleccione el método de pago",
          icon: "info",
        });
        // this.$ajax
        //   .post(`${this.$apitramites}comercios/pagarCuotas`, {
        //     padron: this.user.datoscomercio.padron,
        //     cunica: this.cunicasseleccionadas,
        //     tipo: "ddjj",
        //     pago: "BTOB",
        //   })
        //   .then(function (response) {
        //     console.log("imprimir Interbanking", response);
        //     let content = "";
        //     response.data.forEach(function (item) {
        //       content += `${item.respuesta[0].descripcion}<br>`;
        //       content += `Identificador de deuda: ${item.identificadorDeuda}<br><br>`;
        //     });

        //     obj.$swal.fire({
        //       title: "Resultados de Pagos",
        //       html: content,
        //       icon: "info",
        //     });
        //     obj.vaciarExpanded();
        //   })
        //   .catch(function (error) {
        //     obj.snackbar = true;
        //     obj.msj = error;
        //   });
      }
    },

    AbrirPaginaPago(formulario) {
      const url = this.$router.resolve({
        path:
          "/Pagina_redireccion?" + new URLSearchParams(formulario).toString(), // Reemplaza con el nombre de la ruta de la página de redirección
        params: formulario,
      }).href;
      window.open(url, "_blank");
      this.vaciarExpanded();
    },

    async clickaso(item) {
      console.log("item seleccionado", item);
      // Busca si la cuota ya existe en cuotasseleccionadas
      const cuotaExistente = this.cuotasseleccionadas.findIndex(
        (x) => x.cunica === item.cunica
      );

      if (cuotaExistente !== -1) {
        // Si la cuota ya existe y es específica para tasa 8 y obra 230, elimínala
        if (item.tasa == "8" && item.obra == 230) {
          await this.removeCuota(cuotaExistente);

          // Luego busca y elimina también la cuota para tasa 1 y obra 0
          const cuotaExistenteTasa1 = this.cuotasseleccionadas.findIndex(
            (x) => x.cuota == item.cuota && x.tasa == "1" && x.obra == 0
          );

          if (cuotaExistenteTasa1 !== -1) {
            await this.removeCuota(cuotaExistenteTasa1);
          }
        }
      } else {
        // Si el item tiene tasa 1 o F, sigue este proceso
        if (item.tasa == "1" || item.tasa == "F") {
          // Busca una cuota con tasa 8 y obra 230
          const buscaCuota = await this.getCuota(item.cuota, "8", 230);

          // Asegúrate de que no haya sido seleccionada previamente
          const yaSelecta = this.cuotasseleccionadas.findIndex(
            (x) => x.cuota == item.cuota && x.tasa == "8" && x.obra == 230
          );

          // Si se encuentra una cuota y no está seleccionada, agrégala
          if (buscaCuota.length > 0 && yaSelecta === -1) {
            await this.addCuota(buscaCuota[0]);
          }

          // Finalmente, agrega el item actual
          // await this.addCuota(item);
        }
      }
    },

    // Funciones auxiliares

    async removeCuota(index) {
      this.cuotasseleccionadas.splice(index, 1);
    },

    async getCuota(cuota, tasa, obra) {
      return this.cuotas.filter(
        (v) => v.cuota == cuota && v.tasa == tasa && v.obra == obra
      );
    },

    async addCuota(item) {
      this.cuotasseleccionadas.push(item);
    },

    vaciarExpanded() {
      // this.$emit("vaciarExpanded");
      this.dialog = false;
    },

    Alicuota(index) {
      console.log("entra alicuota", index);
      if (this.actividades[index]) {
        this.actividades[index].alicuota =
          this.actividades[index].actividad.t_valor;
        this.Subtotal(index);
      }
    },
    Subtotal(index) {
      console.log("entra subtotal", index);
      if (this.actividades[index]) {
        this.actividades[index].subtotal =
          this.actividades[index].alicuota * this.actividades[index].ingreso;
        this.calcularTotalSubtotales();
      }
    },
    calcularTotalSubtotales() {
      // Suma los subtotales de todas las actividades
      const totalSubtotales = this.actividades.reduce((total, actividad) => {
        return total + actividad.subtotal;
      }, 0);

      // Ahora `totalSubtotales` contiene el resultado
      console.log(totalSubtotales);

      // Puedes almacenar el total en una propiedad de tu componente si es necesario
      this.totalSubtotales = totalSubtotales;
    },
  },
};
</script>
<style scoped>
.tabla {
  border: 2px solid;
  border-radius: 20px;
  border-color: #638699;
  padding: 0px;
}
</style>
